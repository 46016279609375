$siteWidth: 800px;
$latoFont: "Lato", sans-serif;

$font-family-base: $latoFont;
$line-height-base: 1.1;

$theme-colors: (
  primary: #198754,
  secondary: #0073a1,
  light: #eee,
  dark: #272727,
);
