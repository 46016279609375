@import url("https://fonts.googleapis.com/css?family=Lato:300,400,400i,700");

@import "variables";
@import "mixins";

@import "ember-bootstrap/bootstrap";

body {
  background-image: url("https://pbs.twimg.com/media/FFiP4MjXwAQL_gc.jpg:large");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
  font-family: $latoFont;
  height: 100vh;
}

// .background-container {
//   position: relative;
//   width: 100%;
//   height: 100vh; /* Adjust the height as needed */
//   overflow: hidden;
// }

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://pbs.twimg.com/media/FFiP4MjXwAQL_gc.jpg:large");
  background-size: cover;
  background-position: center;
}

.logo {
  display: block;
  margin: 20px auto;
  max-width: 400px;
  height: auto;
}
.application-wrapper {
  @include wmd();
}
.form-description {
  @include wmd();
}
.btn {
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}

.ember-view {
  margin-top: 5px;
  margin-bottom: 5px;
}

.header {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  opacity: 0.8;
  position: sticky;
  top: 0;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.menu li {
  margin: 0 15px;
}

.menu a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  transition: color 0.3s ease;
}

.menu a:hover {
  color: #ffd700; /* Change to desired hover color */
}

.footer {
  background-color: #171717;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  width: 100%;
  bottom: 0;
  margin-top: 81px;
  min-height: 100px;
  height: 100px;
  font-size: 14px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.footer-section {
  margin: 0 20px;
}

.footer-section h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-section li {
  margin-bottom: 10px;
}

.footer-section a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: #ffd700; /* Change to desired hover color */
}

.footer-social {
  margin-top: 20px;
}

.footer-social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: 10px;
  line-height: 40px;
  font-size: 18px;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.footer-social a:hover {
  background-color: #ffd700; /* Change to desired hover color */
  color: #333;
}

h1,
.form-description,
.complete-text {
  color: black;
}

.error-text {
  color: red;
}

.main-panel {
  min-height: calc(100% - 250px);
}

.account-wrapper > div {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 90%;
}

.btn-dark.disabled {
  background-color: #5cba3c;
  border: none;
}

@media (max-width: 480px) {
  body {
    height: auto;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  body {
    background-size: cover;
    height: auto;
  }
  .footer {
    margin-top: 66px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1880px) {
  body {
    height: auto;
  }
  .footer {
    margin-top: 183px;
  }
}

// @media (max-width: 480px) {
//   .background-image {
//     background-size: 480px auto; /* Adjust background size for smaller phones */
//   }
// }

// @media (min-width: 481px) and (max-width: 768px) {
//   .background-image {
//     background-size: contain; /* Adjust background size for larger phones and small tablets */
//   }
//   .footer {
//     margin-top: 66px;
//   }
// }

// @media only screen and (min-width: 768px) and (max-width: 1880px) {
//   .background-image {
//     background-size: contain; /* Adjust background size for larger phones and small tablets */
//     height: auto;
//   }
//   .footer {
//     margin-top: 183px;
//   }
// }

// @media only screen and (max-width: 768px) {
//   body {
//     height: auto;
//   }
//   .footer {
//     margin-top: 66px;
//   }
// }

// @media only screen and (max-width: 1296px) {
//   .footer {
//     margin-top: -8px;
//   }
// }

// @media only screen and (max-width: 1368px) {
//   .footer {
//     margin-top: 32px;
//   }
// }

// @media only screen and (max-width: 1660px) {
//   .footer {
//     margin-top: 31px;
//   }
// }

// @media only screen and (max-width: 1466px) {
//   .footer {
//     margin-top: 32px;
//   }
// }

@import "ember-power-select/themes/bootstrap";

@import "ember-power-select";
